import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import {route, trans} from '@/Utility/Helpers';
import Instruction from '@/Models/Sessions/Instruction';
import type InstructionPayload from '@/Models/Sessions/InstructionPayload';
import Device from '@/Models/Sessions/Device';
import type {AxiosRequestConfig} from 'axios';
import axios from 'axios';

export default class SessionService {

    public isLoading: boolean = false;
    public isSaving: boolean = false;

    private abortController = new AbortController();

    /**
     * Cancel any ongoing requests
     */
    async cancelRequests(): Promise<any> {
        this.abortController.abort();
        this.abortController = new AbortController();

        return Promise.resolve('Requests canceled');
    }

    async fetchDevices(): Promise<Device[]> {
        return axios
            .get(
                route('api.sessions.devices.index'),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data.data
                    .map((deviceData: any) => {
                        try {
                            return this.parseDevice(deviceData);
                        } catch (ex) {
                            return null;
                        }
                    })
                    .filter((device: Device | null) => device !== null) as Device[];
            });
    }

    async fetchInstructions(): Promise<Instruction[]> {
        if (this.isLoading) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;

        return axios
            .get(
                route('api.sessions.instructions.index'),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data.data
                    .map((instructionData: any) => {
                        try {
                            return this.parseInstruction(instructionData);
                        } catch (ex) {
                            return null;
                        }
                    })
                    .filter((instruction: Instruction | null) => instruction !== null);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async createInstruction(payload: typeof InstructionPayload): Promise<Instruction> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;

        return axios
            .post(
                route('api.sessions.instructions.create'),
                { payload: payload },
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return this.parseInstruction(data.data);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async deleteInstruction(instructionUid: string) {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;

        return axios
            .delete(
                route('api.sessions.instructions.delete', { instruction: instructionUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then()
            .finally(() => {
                this.isSaving = false;
            });
    }

    /**
     * Tries to parse the given instruction data into a valid instruction.
     * Will print and throw usable errors when this fails.
     */
    private parseInstruction(instructionData: any): Instruction {
        try {
            return new Instruction(instructionData);
        } catch (ex) {
            console.error(
                'InstructionService->parseInstruction(): API returned invalid or incompatible instruction data.',
                instructionData,
                ex
            );
            throw new Error(trans('errors.instruction.invalid_data'));
        }
    }

    /**
     * Tries to parse the given device data into a valid device.
     * Will print and throw usable errors when this fails.
     */
    private parseDevice(deviceData: any): Device {
        try {
            return new Device(deviceData);
        } catch (ex) {
            console.error(
                'InstructionService->parseDevice(): API returned invalid or incompatible device data.',
                deviceData,
                ex
            );
            throw new Error(trans('errors.device.invalid_data'));
        }
    }
}
