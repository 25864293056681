import {parseDate} from '@/Utility/Helpers';
import moment from 'moment';

export default class Device {

    /**
     * Number of seconds after which a device is no longer considered to
     * be in managed mode if not checked in by then.
     */
    private static readonly MANAGED_MODE_CHECKIN_TIMEOUT_SECONDS = 60;

    readonly uid: string;
    readonly name: string;

    readonly loaded_unit_uid: string | null;

    readonly last_session_checkin_at: Date | null;
    readonly created_at: Date;
    readonly updated_at: Date;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.name = attributes.name;

        this.loaded_unit_uid = attributes.loaded_unit_uid;

        this.last_session_checkin_at =
            attributes.last_session_checkin_at ? parseDate(attributes.last_session_checkin_at) : null;

        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }

    /**
     * @return True if this device has checked in from managed mode in the near past.
     */
    get isInManagedMode(): boolean {
        return moment(this.last_session_checkin_at)
            .isAfter(moment().subtract(Device.MANAGED_MODE_CHECKIN_TIMEOUT_SECONDS, 'seconds'));
    }

    get isUnitLoaded(): boolean {
        return this.isInManagedMode && this.loaded_unit_uid !== null;
    }
}
