<template>

    <nav id="nav-user">
        <ul>

            <!-- guest (when logged out) -->
            <li v-if="isGuest" class="nav-item">
                <a
                    :href="route('login')"
                    :title="trans('tooltips.navigation.login')"
                    class="nav-link btn btn-login"
                >
                    {{ trans('labels.login') }}
                </a>
            </li>

            <!-- logged in user -->
            <li v-else class="nav-item user-menu">

                <!-- User -->
                <div
                    id="navbarDropdown"
                    ref="navbarDropdown"
                    :title="trans('tooltips.navigation.user')"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    tabindex="0"
                >

                    <!-- Profile image -->
                    <div class="user-image-crop">
                        <img v-if="user!.hasProfileImage" :src="user!.image" alt="" class="user-image">
                        <div v-else class="user-avatar" :data-initials="user!.initials"></div>
                    </div>

                    <!-- User name -->
                    <span v-if="showUserName" class="username">{{ userName }}</span>

                    <!-- User role -->
                    <div class="user-rank">
                        <span :title="tenantNameTooltip">{{ tenantName }}</span>
                    </div>

                </div>

                <!-- extended menu items -->
                <div
                    aria-labelledby="navbarDropdown"
                    class="dropdown-menu dropdown-menu-right"
                >
                    <div class="account">
                        <h3>Account</h3>
                        <div class="details">
                            <!-- User name -->
                            <div class="details-name">{{ user!.fullName }}</div>
                            <div class="details-email">{{ user!.email }}</div>
                        </div>
                        <!-- Edit Profile -->
                        <a
                            v-if="canUpdateOwnUser"
                            :href="route('users.updateForm', {'user': user!.uid})"
                            :title="trans('tooltips.navigation.edit_profile')"
                            class="dropdown-item"
                        >
                            <Icon name="icon_edit" />
                            <span class="label">{{ trans('labels.edit_profile') }}</span>
                        </a>
                    </div>

                    <div class="team">
                        <h3>Team</h3>
                        <div class="details">
                            <div class="details-tenant-name">{{ tenantName }}</div>
                            <div class="details-role">{{ tenantRole }}</div>
                        </div>
                        <!-- Switch Tenants -->
                        <a
                            v-if="canSwitchTenants"
                            :title="trans('labels.change_tenant')"
                            class="dropdown-item"
                            href="#"
                            @click="onSwitchTenantClick"
                        >
                            <Icon name="icon_tenants" />
                            <span class="label">{{ trans('labels.change_tenant') }}</span>
                        </a>
                        <!-- Settings -->
                        <a
                            v-if="canUpdateTenant"
                            :title="trans('labels.settings')"
                            class="dropdown-item"
                            :href="route('tenants.updateForm', {tenant: user!.tenant!.uid})"
                        >
                            <Icon name="icon_settings" />
                            <span class="label">{{ trans('labels.settings') }}</span>
                        </a>
                    </div>

                    <div class="logout">
                        <!-- Logout -->
                        <a
                            :href="route('logout')"
                            :title="trans('tooltips.navigation.logout')"
                            class="dropdown-item"
                        >
                            <Icon name="icon_logout" />
                            <span class="label">{{ trans('labels.logout') }}</span>
                        </a>
                    </div>
                </div>

            </li>

        </ul>
    </nav>

</template>

<script lang="ts">

// Import classes:
import {permission, route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import {defineComponent, inject} from 'vue';
import EventType from '@/Utility/EventType';
import type Tenant from '@/Models/Tenant/Tenant';
import {featureRepositoryKey, tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';
import {Feature} from '@/Models/Features/Feature';
import { Dropdown } from '~bootstrap';

export default defineComponent({

    props: {
        showUserName: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
            featureRepository: inject(featureRepositoryKey)!,
        };
    },

    computed: {
        isGuest() {
            return !window.currentUser;
        },

        user() {
            return window.currentUser;
        },

        canSwitchTenants() {
            return (
                    this.$gate.allows(Permission.TenantsReadAny())
                    || this.user?.belongsToMultipleTenants()
            )
        },

        canUpdateTenant() {
            return this.$gate.allows(Permission.ability(Permission.TenantsUpdate()), this.user?.tenant);
        },

        canUpdateOwnUser() {
            return permission(Permission.UsersUpdateOwn());
        },

        userName() {
            let firstNameLetter = '';

            if (this.user?.firstname) {
                firstNameLetter += this.user?.firstname[0] + '. ';
            }

            return firstNameLetter + this.user?.lastname;
        },

        tenantName() {
            return this.user?.tenant?.name;
        },

        tenantRole() {
            if (!this.user?.tenant_role) {
                throw new Error('user and tenant role must be set');
            }

            return trans('tenant_roles.' + this.user.tenant_role.name);
        },

        tenantNameTooltip() {
            return `${this.tenantName} (${this.tenantRole})`;
        },
    },

    methods: {
        route,
        trans,

        onSwitchTenantClick() {
            Dropdown.getInstance(this.$refs.navbarDropdown).hide();
            this.$globalEvents.once(EventType.MODAL_SELECT_TENANT_APPLY, this.onTenantSelected);
            this.$globalEvents.once(EventType.MODAL_SELECT_TENANT_CANCEL, this.removeTenantSelectionModalListeners);

            this.$globalEvents.emit(EventType.MODAL_SELECT_TENANT_SHOW);
        },

        removeTenantSelectionModalListeners() {
            this.$globalEvents.off(EventType.MODAL_SELECT_TENANT_APPLY, this.onTenantSelected);
            this.$globalEvents.off(EventType.MODAL_SELECT_TENANT_CANCEL, this.removeTenantSelectionModalListeners);
        },

        onTenantSelected(tenant: Tenant) {
            this.removeTenantSelectionModalListeners();

            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.changing_tenant'));

            this.tenantService
                .switchTenant(tenant)
                .then(() => {
                    // reload page to update references
                    window.location.reload();
                })
                .catch(this.onErrorApi);
        },

        onErrorApi(error: string | Error) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);

            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }

            this.$root?.showErrorDialog(error);
        },
    },
});
</script>

<style lang="scss" scoped>

#nav-user {

    a {
        color: var(--color-anthracite);

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
            color: var(--color-primary-hover);
        }
    }

    .nav-link {
        padding: 12px 2px 15px 2px;
        cursor: pointer;

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
            .icon-more,
            .dropdown-item {
                color: var(--color-primary-hover);
            }
            .user-image-crop {
                border-color: var(--color-anthracite40);
                background-color: var(--color-anthracite40);

                .user-avatar {
                    &[data-initials]:before {
                        background-color: var(--color-anthracite40);
                    }
                }
            }
        }
    }

    .user-menu {
        position: relative;
        text-align: center;
        font-size: var(--font-size-small);

        .dropdown-menu {
            overflow: hidden;
            top: auto !important;
            bottom: 10px !important;
            left: 90% !important;
            padding: 0 !important;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16);
            transform: none !important;

            .account,
            .team,
            .logout {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid var(--color-anthracite20);
                white-space: nowrap;
                font-size: var(--font-size-small);

                &:last-child {
                    border-bottom: 0;
                }

                h3 {
                    color: var(--color-anthracite);
                    font-size: var(--font-size-default);
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin-bottom: 0.25rem;
                }

                .details {
                    font-size: var(--font-size-default);
                    color: var(--color-anthracite80);
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin-bottom: 0.25rem;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }

                    .details-email,
                    .details-role {
                        color: var(--color-anthracite40);
                    }
                }
            }

            .dropdown-item {
                display: flex;
                padding: 0.25rem 1rem;
                gap: 0.5rem;

                .label {
                    align-self: center;
                }

                .icon {
                    align-self: center;
                    flex-basis: 20px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .username {
        position: relative;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        font-family: var(--font-family-condensed-demibold);
    }

    .user-rank {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-family: var(--font-family-condensed-demibold);

        span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .username + .user-rank {
        font-family: var(--font-family-condensed);
    }

    .user-image-crop {
        width: 44px;
        height: 44px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 0 auto 4px auto;
        border: 2px solid var(--color-anthracite80);
        background-color: var(--color-anthracite80);

        .user-avatar {
            width: 40px;
            height: 40px;

            &[data-initials]:before {
                color: var(--color-white);
                background-color: var(--color-anthracite80);
                font-family: var(--font-family-condensed-demibold);
                font-size: var(--font-size-default);
                opacity: 1;
                content: attr(data-initials);
                display: inline-block;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 0.5em;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .user-image {
            display: inline-block;
            margin: 0 auto;
            // margin-left: -25%; //centers the image // does not work with our dummy image
            height: 100%;
            width: auto;
        }
    }

    .dropdown-toggle {
        &:after {
            display: none;
        }
    }

    .btn-login {
        min-width: 100%;
        padding-bottom: 20px;
    }
}
</style>
