<template>
    <UnitRevisionLine :unit-revision="unitRevision">
        <template #empty>
            <LoadingIndicator />
        </template>
    </UnitRevisionLine>
</template>

<script lang="ts">
import {defineComponent, type PropType} from 'vue';
import type Unit from '@/Models/Unit/Unit';
import type InstructionPayloadUnitStart from '@/Models/Sessions/InstructionPayloadUnitStart';
import UnitRevisionLine from '@/Vue/Sessions/UnitRevisionLine.vue';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';

export default defineComponent({
    components: {
        LoadingIndicator,
        UnitRevisionLine
    },

    props: {
        payload: {
            type: Object as PropType<InstructionPayloadUnitStart>,
            required: true,
        },
        units: {
            type: Object as PropType<Unit[]>,
            required: true,
        }
    },

    computed: {
        unitRevision() {
            return this.units.find(unit => unit.uid === this.payload.unit_uid)?.latestReleasedRevision;
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
