<template>
    <div class="session-instruction">
        <h3 class="title">
            {{ instruction.title }}
        </h3>

        <p class="description">
            {{ instruction.description }}
        </p>

        <div class="payload">
            <component
                :is="payloadComponent"
                :payload="instruction.payload"
                :units="units"
            />
        </div>

        <ButtonCustom
            :caption="trans('labels.remove')"
            :disabled="!canDelete"
            class="btn-red"
            icon="icon_delete"
            @trigger="onTriggerDelete"
        />
    </div>
</template>

<script lang="ts">
import {defineComponent, type PropType} from 'vue';
import type Instruction from '@/Models/Sessions/Instruction';
import ButtonCustom from '@/Vue/Common/ButtonCustom.vue';
import {trans} from '@/Utility/Helpers';
import SessionInstructionPayloadUnitStart from '@/Vue/Sessions/SessionInstructionPayloadUnitStart.vue';
import InstructionPayloadUnitStart from '@/Models/Sessions/InstructionPayloadUnitStart';
import type Unit from '@/Models/Unit/Unit';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        ButtonCustom,
    },

    props: {
        instruction: {
            type: Object as PropType<Instruction>,
            required: true,
        },
        units: {
            type: Object as PropType<Unit[]>,
            required: true,
        }
    },

    emits: [
        'delete'
    ],

    computed: {
        payloadComponent() {
            switch (this.instruction.payload.type) {
                case InstructionPayloadUnitStart.type:
                    return SessionInstructionPayloadUnitStart;
                default:
                    throw Error(`No component found for instruction payload of type '${this.instruction.payload.type}'.`);
            }
        },

        canDelete() {
            return this.$gate.allows(Permission.SessionsUpdate()) && this.$gate.allows(Permission.InstructionsDelete());
        },
    },

    methods: {
        trans,

        onTriggerDelete() {
            this.$emit('delete', this.instruction);
        },
    }
});
</script>

<style lang="scss" scoped>

.session-instruction {
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: auto auto;
    gap: 0 16px;
    background-color: white;
    border-radius: var(--card-border-radius);
    padding: 16px;

    grid-template-areas:
        'headline buttons'
        'description buttons'
        'payload buttons';

    .title {
        grid-area: headline;
        margin-bottom: 4px;
    }

    .description {
        grid-area: description;
        margin-bottom: 16px;
    }

    .payload {
        grid-area: payload;
    }

    .btn {
        grid-area: buttons;
        align-self: end;
    }
}

</style>
