<template>
    <section class="session-devices">
        <h2>
            {{ trans('sessions.devices.headline') }}
            <LoadingIndicator v-if="isFetchingDevices" />
        </h2>
        <ul>
            <li class="header">
                <span>{{ trans('sessions.devices.device_name_headline') }}</span>
                <span>{{ trans('sessions.devices.is_in_managed_mode_headline') }}</span>
                <span>{{ trans('sessions.devices.is_unit_loaded_headline') }}</span>
            </li>
            <session-devices-list-item
                v-for="device in devices"
                :key="device.uid"
                :device="device"
            />
            <li v-if="devices.length === 0 && hasFetchedDevices" class="no-items-available">
                {{ trans('sessions.devices.no_devices_message') }}
            </li>
        </ul>
    </section>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers';
import {sessionServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import type Device from '@/Models/Sessions/Device';
import AuthorizationError from '@/Errors/AuthorizationError';
import SessionDevicesListItem from '@/Vue/Sessions/SessionDevicesListItem.vue';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';

export default defineComponent({
    components: {
        LoadingIndicator,
        SessionDevicesListItem
    },

    data() {
        return {
            sessionService: inject(sessionServiceKey)!,
            devices: [] as Device[],
            reloadInterval: 0,
            isFetchingDevices: false,
            hasFetchedDevices: false,
        };
    },

    mounted() {
        this.fetchDevices();
        this.reloadInterval = setInterval(() => this.fetchDevices(), 10_000);
    },

    beforeUnmount() {
        clearInterval(this.reloadInterval);
    },

    methods: {
        trans,

        async fetchDevices() {
            this.isFetchingDevices = true;

            return this.sessionService
                .fetchDevices()
                .then((devices) => {
                    this.devices = devices;
                })
                .catch(this.onErrorApi)
                .finally(() => {
                    this.isFetchingDevices = false;
                    this.hasFetchedDevices = true;
                });
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }
            this.$root?.showErrorDialog(error);
        },
    },
});
</script>

<style lang="scss" scoped>

.session-devices {
    margin-top: 24px;

    h2 > .loading-indicator {
        margin: 0 0 -2px 4px;
        width: 20px;
        height: 20px;
    }

    ul {
        display: grid;
        grid-template-columns: 300px max-content max-content;
        gap: 4px 32px;
        list-style-type: none;

        li.header {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: subgrid;

            padding: 10px;
            background-color: var(--color-anthracite);
            color: white;
            border-radius: 8px;
        }

        .no-items-available {
            grid-column: 1 / -1;
        }
    }
}

</style>
