<template>
    <div v-if="unitRevision" class="unit-revision-line">
        <img :src="unitRevision.preview_thumbnail" alt="" class="preview">
        <h3 class="title">
            {{ unitRevision.title }}
        </h3>
        <p class="description">
            {{ unitRevision.description }}
        </p>
    </div>
    <div v-else class="unit-revision-line empty">
        <slot name="empty">
            {{ trans('sessions.instructions.select_unit_placeholder') }}
        </slot>
    </div>
</template>

<script lang="ts">
import {defineComponent, type PropType} from 'vue';
import type UnitRevision from '@/Models/Unit/UnitRevision';
import {trans} from '@/Utility/Helpers';

export default defineComponent({
    props: {
        unitRevision: {
            type: null as PropType<UnitRevision | null>,
            required: true,
        },
    },

    methods: {
        trans
    },
});
</script>

<style lang="scss" scoped>

.unit-revision-line {

    display: grid;
    gap: 0 16px;
    grid-template-columns: min-content auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        'preview headline'
        'preview description';

    .preview {
        grid-area: preview;
        border-radius: var(--card-border-radius-small);
        max-width: 200px;
    }

    .title {
        grid-area: headline;
        align-self: end;
        font-family: var(--font-family-condensed);

        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .description {
        grid-area: description;
        align-self: start;

        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &.empty {
        grid-template-columns: auto;
        grid-template-rows: auto;
        font-style: italic;
    }
}

</style>
