<template>
    <main
        id="layout-main"
        :data-loading="isLoading"
        :data-saving="isSaving"
    >

        <PageHeader
            :page-title="trans('sessions.index.headline')"
        />

        <div id="layout-content">
            <div id="content">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="trans('sessions.index.copy')" />

                <section>
                    <h2>{{ trans('sessions.index.active_instructions_headline') }}</h2>
                    <SessionInstruction
                        v-if="instruction"
                        :instruction="instruction"
                        :units="units"
                        @delete="onTriggerDeleteInstruction"
                    />
                    <NoItemsAvailable v-else-if="!isLoading" />
                </section>

                <section v-if="canCreateInstruction">
                    <h2>{{ trans('sessions.index.new_instruction_headline') }}</h2>
                    <div v-if="instruction === null" class="new-instruction-form">
                        <h3 class="title">
                            {{ newInstructionTitle }}
                        </h3>

                        <p class="description">
                            {{ newInstructionDescription }}
                        </p>

                        <UnitRevisionLine :unit-revision="selectedUnitRevision" class="payload" />

                        <div class="buttons">
                            <ButtonSecondary
                                :caption="trans('labels.select_unit')"
                                @trigger="onTriggerSelectUnit"
                            />
                            <ButtonPrimary
                                :caption="trans('labels.save')"
                                :disabled="!selectedUnitRevision"
                                @trigger="onTriggerSaveInstruction"
                            />
                        </div>
                    </div>
                    <p v-else>
                        {{ trans('sessions.index.new_instruction_only_single') }}
                    </p>
                </section>

                <session-devices />
            </div>

            <SidepanelUnitRevisions
                :key="'sidepanel-unit-revisions-'+units.length"
                :units="units"
            />

            <!-- Modals go here -->
            <ModalNotification />
            <ModalProgress />

        </div>
    </main>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import SidepanelUnitRevisions from '@/Vue/Sidepanel/SidepanelUnitRevisions.vue';
import EventType from '@/Utility/EventType';
import type Unit from '@/Models/Unit/Unit';
import {defineComponent, inject} from 'vue';
import {sessionServiceKey, unitServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';
import type UnitRevision from '@/Models/Unit/UnitRevision';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import Instruction from '@/Models/Sessions/Instruction';
import InstructionPayloadUnitStart from '@/Models/Sessions/InstructionPayloadUnitStart';
import SessionInstruction from '@/Vue/Sessions/SessionInstruction.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import UnitRevisionLine from '@/Vue/Sessions/UnitRevisionLine.vue';
import SessionDevices from '@/Vue/Sessions/SessionDevices.vue';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import {Permission} from '@/Models/User/Permission';
import type Device from '@/Models/Sessions/Device';

export default defineComponent({
    components: {
        NoItemsAvailable,
        UnitRevisionLine,
        ButtonPrimary,
        SessionInstruction,
        ModalProgress,
        ModalNotification,
        SidepanelUnitRevisions,
        ButtonSecondary,
        PageHeader,
        SessionDevices,
    },

    data() {
        return {
            sessionService: inject(sessionServiceKey)!,
            unitService: inject(unitServiceKey)!,

            events: new Map([
                [EventType.SIDEPANEL_UNITREVISIONS_SELECT, this.onSelectUnitRevision],
            ]),

            units: [] as Unit[],
            selectedUnitRevision: null as UnitRevision | null,

            /**
             * For now, we only support a single unit_start instruction at a time.
             * Change to an array, once multiple instructions should be supported.
             */
            instruction: null as Instruction | null,

            devices: [] as Device[],
        };
    },

    computed: {
        canCreateInstruction() {
            return this.$gate.allows(Permission.SessionsUpdate()) && this.$gate.allows(Permission.InstructionsCreate());
        },

        newInstructionTitle() {
            return Instruction.getTitle(InstructionPayloadUnitStart.type);
        },

        newInstructionDescription() {
            return Instruction.getDescription(InstructionPayloadUnitStart.type);
        },

        isLoading(): boolean {
            if (this.unitService.isLoading || this.sessionService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isSaving(): boolean {
            if (this.unitService.isSaving || this.sessionService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });

        this.fetchInstructions()
            .then(this.fetchUnits);
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        trans,

        async fetchInstructions() {
            return this.sessionService
                .fetchInstructions()
                .then((instructions) => {
                    if (instructions.length > 1) {
                        throw Error('At the moment we only support a single unit_start instruction at a time.');
                    }

                    this.instruction = instructions[0] || null;
                })
                .catch(this.onErrorApi);
        },

        async fetchUnits() {
            return this.unitService
                .fetchUnits(1, 999999, { status: ['released'] }, null, 'title')
                .then(data => {
                    this.units = data.unitList;
                })
                .catch(this.onErrorApi);
        },

        onSelectUnitRevision(unitRevision: UnitRevision) {
            this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_HIDE);

            this.selectedUnitRevision = unitRevision;
        },

        onTriggerSelectUnit() {
            this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_SHOW);
        },

        onTriggerSaveInstruction() {
            const unitStartInstructionPayload = new InstructionPayloadUnitStart({
                unit_uid: this.selectedUnitRevision!.unit_uid
            });

            this.sessionService
                .createInstruction(unitStartInstructionPayload)
                .then(instruction => {
                    this.instruction = instruction;
                })
                .catch(this.onErrorApi);
        },

        onTriggerDeleteInstruction(instruction: Instruction) {
            this.sessionService
                .deleteInstruction(instruction.uid)
                .then(() => {
                    this.selectedUnitRevision = null;
                    this.instruction = null;
                })
                .catch(this.onErrorApi);
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }
            this.$root?.showErrorDialog(error);
        },
    }
});
</script>

<style lang="scss" scoped>
#content {
    > * {
        max-width: var(--container-max-width);
        min-width: 670px;
    }

    section h2 {
        margin-top: 24px;
    }
}

.new-instruction-form {
    display: grid;
    grid-template-columns: auto fit-content(300px);
    grid-template-rows: auto auto;
    gap: 0 16px;
    background-color: white;
    border-radius: var(--card-border-radius);
    padding: 16px;

    grid-template-areas:
        'headline buttons'
        'description buttons'
        'payload buttons';

    .title {
        grid-area: headline;
        margin-bottom: 4px;
    }

    .description {
        grid-area: description;
        margin-bottom: 16px;
    }

    .payload {
        grid-area: payload;
    }

    .buttons {
        grid-area: buttons;
        align-self: end;
    }
}

</style>
