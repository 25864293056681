import type InstructionPayload from '@/Models/Sessions/InstructionPayload';
import InstructionPayloadFactory from '@/Models/Sessions/InstructionPayloadFactory';
import {parseDate, trans} from '@/Utility/Helpers';

export default class Instruction {

    static getTitle(type: string) {
        return trans(`sessions.instructions.types.${type}.title`);
    }

    static getDescription(type: string) {
        return trans(`sessions.instructions.types.${type}.description`);
    }

    readonly uid: string;
    readonly tenant_uid: string;

    readonly payload: InstructionPayload;

    readonly created_at: Date;
    readonly updated_at: Date;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.tenant_uid = attributes.tenant_uid;

        this.payload = InstructionPayloadFactory.createFromAttributes(attributes.payload);

        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }

    get title() {
        return Instruction.getTitle(this.payload.type);
    }

    get description() {
        return Instruction.getDescription(this.payload.type);
    }
}
