<template>
    <li :class="{ 'is-in-managed-mode': device.isInManagedMode }" class="device-list-item">
        <span class="device-name">{{ device.name }}</span>
        <span>
            <Icon v-if="device.isInManagedMode" name="icon_check" />
            <Icon v-else name="icon_remove" />
        </span>
        <span>
            <Icon v-if="device.isUnitLoaded" name="icon_check" />
            <Icon v-else name="icon_remove" />
        </span>
    </li>
</template>

<script lang="ts">
import {defineComponent, type PropType} from 'vue';
import {trans} from '@/Utility/Helpers';
import type Device from '@/Models/Sessions/Device';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({
    components: {
        Icon
    },

    props: {
        device: {
            type: Object as PropType<Device>,
            required: true,
        },
    },

    methods: {
        trans,
    },
});
</script>

<style lang="scss" scoped>

.device-list-item {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    padding: 6px 10px;
    border-radius: 8px;

    &.is-in-managed-mode {
        background-color: white;
    }

    &:not(.is-in-managed-mode) {
        background-color: var(--color-anthracite10);
    }

    span.device-name {
        font-family: var(--font-family-mono-regular);
        word-wrap: anywhere;
    }

    span:has(> .icon) {
        justify-self: center;
        margin-top: -2px;

        .icon {
            width: 16px;
            height: 16px;
        }
    }
}

</style>
