import InstructionPayload from '@/Models/Sessions/InstructionPayload';

export default class InstructionPayloadUnitStart extends InstructionPayload {

    static type: string = 'unit_start';

    readonly unit_uid: string;

    constructor(attributes: { unit_uid: string }) {
        super(attributes);

        this.unit_uid = attributes.unit_uid;
    }
}
